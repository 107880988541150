import Link from 'next/link'
import { useAuth0 } from '@auth0/auth0-react'
import { Popover, Transition } from '@headlessui/react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { hasRequiredRole } from '@/util/hasRequiredRole'
import axios from 'axios'

const UserAuth = () => {
  const {
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0()

  const [customerPortal, setCustomerPortal] = useState('')

  useEffect(() => {
    const portal = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently()
          const apiUrl = process.env.NEXT_PUBLIC_API_URL
          const customerID =
            user[`${process.env.NEXT_PUBLIC_AUDIENCE}/customerid`]
              .stripe_customer_id
          console.log(`${apiUrl}/user/portal?customer_id=${customerID}`)
          const res = await axios.get(
            `${apiUrl}/user/portal?customer_id=${customerID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          setCustomerPortal(res.data)
          console.log(res.data)
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
    }
    portal()
  }, [isAuthenticated, user])

  const userLinks = useMemo(
    () => [
      {
        url: '/profile',
        text: 'Profile',
        id: 'user:profile_link_click',
      },
      {
        text: 'Manage Subscription',
        id: 'user:manage_subscription_link_click',
        url: customerPortal,
      },
      {
        text: 'Logout',
        id: 'user:profile_link_click',
        action: () =>
          logout({
            logoutParams: {
              returnTo: process.env.NEXT_PUBLIC_AUTH0_LOGOUT_REDIRECT_URL,
            },
          }),
      },
    ],
    [customerPortal, logout]
  )
  if (isLoading) {
    return null
  }

  return (
    <>
      {isAuthenticated ? (
        user && (
          <>
            <Popover className="relative inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500">
              <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-500 dark:text-white focus:outline-none">
                <span>
                  <img
                    className="inline-block h-8 w-8 rounded-full bg-white"
                    src={`${user?.picture}`}
                    alt="user profile image"
                  />
                </span>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-1/4 mt-56 flex w-screen max-w-min -translate-x-1/2 px-4  z-[1000]">
                  <div className="w-40 shrink rounded-xl bg-white dark:bg-dark p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5]">
                    {hasRequiredRole({
                      user,
                      requiredRole: 'admin',
                    }) && (
                      <Link
                        href={'/admin/dashboard'}
                        className="block p-2 hover:border-gray-300 hover:text-gray-400 dark:text-white "
                      >
                        Admin Dash
                      </Link>
                    )}
                    {userLinks.map((item) =>
                      item.url ? (
                        <Link
                          id={item.id}
                          key={item.text}
                          href={item.url}
                          className="block p-2 hover:border-gray-300 hover:text-gray-400 dark:text-white "
                        >
                          {item.text}
                        </Link>
                      ) : (
                        <div
                          id={item.id}
                          key={item.text}
                          className="block p-2 hover:border-gray-300 hover:text-gray-400 dark:text-white cursor-pointer"
                          onClick={item.action}
                        >
                          {item.text}
                        </div>
                      )
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </>
        )
      ) : (
        <div className={'flex items-center justify-center gap-2'}>
          <button
            className="rounded-full border-2 border-black dark:border-white  px-2 md:px-4 py-0.5 text-sm font-semibold text-black hover:text-white dark:text-white shadow-sm hover:bg-indigo-500"
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: 'login',
                },
              })
            }
          >
            Login
          </button>
          <button
            className="rounded-full bg-indigo-600 px-2 md:px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: 'signup',
                },
              })
            }
          >
            Signup
          </button>
        </div>
      )}
    </>
  )
}

export default UserAuth
