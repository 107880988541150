import 'leaflet/dist/leaflet.css'
import Image from 'next/image'
import TimeLine from '@/components/Landing/TimeLine'
import Pricing from '@/components/Landing/Pricing'
import Footer from '@/components/Landing/Footer'
import NewsLetter from '@/components/Landing/NewsLetter'

export default function Home() {
  return (
    <div className="text-white bg-dark overflow-hidden">
      <div className="w-full min-h-screen bg-gradient-to-b from-purpleGradientStart to-darkGradientEnd px-8 flex flex-col items-center justify-start">
        <div className="max-w-screen-2xl w-full bg-white rounded-3xl overflow-hidden shadow-2xl mt-12 ">
          <div className="relative h-video">
            <Image
              src="https://placehold.co/600x400"
              alt="Delicious meal in a patterned bowl"
              width={600}
              height={400}
              className="w-full h-video md:h-auto "
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col justify-end p-4 md:p-12">
              <h2 className="text-white text-3xl md:text-5xl font-bold leading-tight mb-4 w-full  md:w-3/5">
                Discover meals you can trust with YumDodo
              </h2>
              <button className="bg-indigo-600 text-white font-semibold py-2 px-6 rounded-full hover:bg-indigo-700 transition-colors w-36">
                Join Now
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 px-4 max-w-screen-2xl w-full">
          <p className="text-xs text-gray-400">
            <span className="font-semibold">Disclaimer:</span> We are not
            perfect. We recommend our customers to always confirm food
            ingredients and kitchen practices with restaurant staff.
          </p>
        </div>
      </div>

      <div className="w-full pb-12 px-4">
        <h2 className="mx-auto max-w-4xl text-center text-3xl  font-bold text-white leading-tight">
          Have food intolerances, or trying to find your next spectacular meal?
          YumDodo has your back.
        </h2>
      </div>

      <div className={`mx-auto w-80`}>
        <NewsLetter />
      </div>
      <TimeLine />
      <Pricing />
      <Footer />
    </div>
  )
}
